import { AssetReq } from 'src/modules/Asset/models';
import {
  Config,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';

export const getTenantAssetDataAPI = async (req: AssetReq) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => { }
  };
  const url = `/v1/asset/tenant/${req.tenantId}?isExpenses=${req.isExpenses ? 'true' : 'false'}`;
  const res = await apiGet(url);
  return res.data;
};

export const getFutureBookingByAssetIdAPI = async (assetId: number) => {
  const res = await apiGet(`/v1/booking/asset/${assetId}`);
  return res.data;
};

export const getExpensesByAssetIdAPI = async (assetId: number) => {
  const res = await apiGet(`/v1/expence/asset/${assetId}`);
  return res.data;
};


export const deleteTenantUserAPI = async (payload: any) => {
  const res = await apiDelete(`/v1/asset/${payload.id}/tenant/${payload.tenantId}`, payload);
  return res.data;
};

export const sellAssetAPI = async (payload: any) => {
  const res = await apiPut(`/v1/asset/${payload.id}/sold`, payload);
  return res.data;
};

export const addAssetAPI = async (payload: any) => {
  const res = await apiPost(`/v1/asset`, payload);
  return res.data;
};

export const updateAssetAPI = async (payload: any) => {
  const res = await apiPut(`/v1/asset`, payload);
  return res.data;
};

export const updateTenantUserStatusAPI = async (userData: any) => {
  const res = await apiPatch(
    `/tenant/${userData.tenantId}/user/${userData.email}/enable`,
    { value: userData.value }
  );
  return res.data;
};

export const getUserAssetsDataAPI = async (payload) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => { }
  };
  const res = await apiGet(
    `/organization/${payload.orgId}/user/${payload.user}/assets`
  );
  return res.data;
};

export const deleteUserAssetAPI = async (payload: any) => {
  const res = await apiDelete(
    `organization/${payload.tenantId}/assets/${payload.assetId}/user/${payload.userId}`
  );
  return res.data;
};
